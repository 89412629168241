import { ThemeProvider } from "@mui/material";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { getTheme } from "./util/theme";
import { useMobileWindow } from "./util/responsive";
import HomePage from "./HomePage";
import "./App.css";

function App() {
  const isMobile = useMobileWindow();
  const theme = getTheme(isMobile);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
