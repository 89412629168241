import { Button, Fade, Stack, Typography, Link } from "@mui/material";
import { APP_BUTTON, LANDING_SUBTITLE, LANDING_TITLE } from "../util/labels";
import { useMobileWindow } from "../util/responsive";
import { VaultCarousel } from "./../components";

export default function Landing() {
  const isMobile = useMobileWindow();

  const WebView = () => {
    return (
      <Fade in appear timeout={800}>
        <Stack
          direction="row"
          sx={{
            marginLeft: "240px",
            marginRight: "240px",
            height: "90vh",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {" "}
          <Stack
            spacing={4}
            sx={{
              width: "550px",
              minWidth: "550px",
              maxWidth: "550px",
              marginTop: "-100px",
            }}
          >
            <Typography variant="h1">{LANDING_TITLE}</Typography>
            <Stack direction="row" spacing={2}>
              <Typography variant="body1">{LANDING_SUBTITLE}</Typography>
              <a href="https://app.bridgesplit.com" rel="noreferrer" target="_blank">
              <Button
                disableElevation
                sx={{ width: "200px", maxHeight: "50px" }}
                variant="contained"
                color="primary"
              >
                {APP_BUTTON}
              </Button>
              </a>
            </Stack>
          </Stack>
          <VaultCarousel />
        </Stack>
      </Fade>
    );
  };

  const MobileView = () => {
    return (
      <Fade in appear timeout={800}>
        <Stack
          sx={{
            marginBottom: "50px",
            marginTop: "100px",
            paddingLeft: "20px",
            paddingRight: "20px",
            textAlign: "center",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
          spacing={2}
        >
          <Typography
            sx={{
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
            variant="h1"
          >
            {LANDING_TITLE}
          </Typography>
          <Typography variant="body1">{LANDING_SUBTITLE}</Typography>
          <a href="https://app.bridgesplit.com" rel="noreferrer" target="_blank"><Button
            disableElevation
            sx={{ width: "160px" }}
            variant="contained"
            color="primary"
          >
            {APP_BUTTON}
          </Button>
          </a>
          <VaultCarousel />
        </Stack>
      </Fade>
    );
  };

  return isMobile ? <MobileView /> : <WebView />;
}
