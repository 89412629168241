import { createTheme, PaletteMode, ThemeOptions } from "@mui/material";
import { amber, grey, deepOrange } from "@mui/material/colors";

export const getTheme = (isMobile) => {
  const mobileTheme = {
    spacing: 10,
    palette: {
      primary: {
        main: "#0048FF",
        dark: "#0020ca",
        light: "#6d74ff",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#131419",
        light: "#EEEEF2",
        dark: "#000000",
        contrastText: "#FFFFFF",
      },
      info: {
        main: "#131419",
        light: "#EEEEF2",
        dark: "#000000",
        contrastText: "#FFFFFF",
      },
      background: {
        default: "#FFFFFF",
        paper: "#FFFFFF",
      },
    },
    typography: {
      fontSize: 16,
      htmlFontSize: 16,
      fontFamily:
        '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
      h1: {
        fontSize: "50px",
        fontWeight: 500,
      },
      h2: {
        fontSize: "35px",
        fontWeight: 500,
      },
      h3: {
        fontSize: "20px",
        fontWeight: 500,
        textTransform: "uppercase",
      },

      h6: {
        fontSize: "16px",
        fontWeight: 500,

        lineHeight: "22px",
      },
      body1: {
        fontSize: "16px",
        lineHeight: "22px",
      },
      body2: {
        fontSize: "16px",
      },

      button: {
        fontSize: "16px",
        fontWeight: 500,
        boderColor: "#0048ff !important",
        borderWidth: "1px !important",
        textTransform: "none",
      },
    },
  };
  const webTheme = {
    spacing: 10,
    palette: {
      primary: {
        main: "#0048FF",
        dark: "#0020ca",
        light: "#6d74ff",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#131419",
        light: "#EEEEF2",
        dark: "#000000",
        contrastText: "#FFFFFF",
      },
      info: {
        main: "#131419",
        light: "#EEEEF2",
        dark: "#000000",
        contrastText: "#FFFFFF",
      },
      background: {
        default: "#FFFFFF",
        paper: "#FFFFFF",
      },
    },
    typography: {
      fontSize: 16,
      htmlFontSize: 16,
      fontFamily:
        '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
      h1: {
        fontSize: "90px",
        fontWeight: 500,
      },
      h2: {
        fontSize: "50px",
        fontWeight: 500,
      },
      h3: {
        fontSize: "24px",
        fontWeight: 500,
        textTransform: "uppercase",
      },

      h6: {
        fontSize: "16px",
        fontWeight: 500,

        lineHeight: "22px",
      },
      body1: {
        fontSize: "16px",
        lineHeight: "22px",
      },
      body2: {
        fontSize: "16px",
      },

      button: {
        fontSize: "16px",
        fontWeight: 500,
        boderColor: "#0048ff !important",
        borderWidth: "1px !important",
        textTransform: "none",
      },
    },
  };
  return createTheme(isMobile ? mobileTheme : webTheme);
};
