import { Paper, Grid, Typography, Stack, Link } from "@mui/material";
import { blackBackground, logoBlack, mock } from "../assets";
import {
  PRODUCT_BODY_1,
  PRODUCT_OVERLINE,
  PRODUCT_SUBTITLE_1,
  PRODUCT_BODY_2,
  PRODUCT_BODY_3,
  PRODUCT_BODY_4,
  PRODUCT_SUBTITLE_2,
  PRODUCT_SUBTITLE_3,
  PRODUCT_SUBTITLE_4,
  PRODUCT_TITLE,
  PRODUCT_SUBTITLE_5,
  PRODUCT_BODY_5,
  PRODUCT_BODY_6,
  PRODUCT_SUBTITLE_6,
  PRODUCT_LINK_3,
  PRODUCT_LINK_4,
  PRODUCT_LINK_5,
  PRODUCT_LINK_6,
  LEARN_MORE,
  COMING_SOON,
} from "../util/labels";
import Card from "react-animated-3d-card";
import { styled } from "@mui/system";
import {
  ArrowForwardIosRounded,
  CodeRounded,
  SwapHorizRounded,
  ExtensionRounded,
  PieChartRounded,
  ShowChartRounded,
  AccountBalanceRounded,
} from "@mui/icons-material";
import { useMobileWindow } from "../util/responsive";

const FeatureTitle = styled(Typography)(() => ({
  opacity: 0.5,
  letterSpacing: "2px",
  textTransform: "uppercase",
}));
function FeatureCard(props) {
  const isMobileView = useMobileWindow();
  const NestedCard = () => {
    return (
      <Card
        style={{
          backgroundColor: "#282937",
          cursor: "pointer",
          padding: "20px",
        }}
        borderRadius={"10px"}
        shineStrength={0.2}
        onClick={() => {
          props.link ? window.open(props.link) : console.log("No link");
        }}
      >
        <Stack
          sx={{
            minHeight: "110px",
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={2}>
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
              <FeatureTitle variant="h6" sx={{ color: "#FFFFFF" }}>
                {props.title}
              </FeatureTitle>
              {props.icon}
            </Stack>

            <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
              {props.body}
            </Typography>
            {props.link ? (
              <Typography
                variant="h6"
                sx={{
                  color: "#FFFFFF",
                  opacity: 0.5,
                  alignSelf: "flex-end",
                  justifySelf: "flex-end",
                }}
              >
                {LEARN_MORE}{" "}
                <ArrowForwardIosRounded
                  sx={{
                    height: "15px",
                    width: "15px",
                    marginBottom: "-2px",
                  }}
                >
                  {" "}
                </ArrowForwardIosRounded>
              </Typography>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  color: "#FFFFFF",
                  opacity: "0.5",
                  backgroundColor: "#3D3E4B",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  textAlign: "center",
                  width: "140px",
                  borderRadius: "5px",
                  alignSelf: "flex-end",
                  justifySelf: "flex-end",
                }}
              >
                {COMING_SOON}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Card>
    );
  };

  const MobileCard = () => {
    return (
      <Grid item xs={12}>
        <NestedCard />
      </Grid>
    );
  };
  const WebCard = () => {
    return (
      <Grid item xs={6}>
        <NestedCard />
      </Grid>
    );
  };
  return isMobileView ? <MobileCard /> : <WebCard />;
}

export default function Product() {
  const isMobile = useMobileWindow();
  const NestedCards = () => {
    return (
      <>
        <FeatureCard
          title={PRODUCT_SUBTITLE_1}
          body={PRODUCT_BODY_1}
          icon={<PieChartRounded sx={{ color: "#FFFFFF", opacity: 0.5 }} />}
        />

        <FeatureCard
          title={PRODUCT_SUBTITLE_2}
          body={PRODUCT_BODY_2}
          icon={
            <AccountBalanceRounded sx={{ color: "#FFFFFF", opacity: 0.5 }} />
          }
        />
        <FeatureCard
          title={PRODUCT_SUBTITLE_3}
          body={PRODUCT_BODY_3}
          icon={<ExtensionRounded sx={{ color: "#FFFFFF", opacity: 0.5 }} />}
          link={PRODUCT_LINK_3}
        />
        <FeatureCard
          title={PRODUCT_SUBTITLE_4}
          body={PRODUCT_BODY_4}
          icon={<SwapHorizRounded sx={{ color: "#FFFFFF", opacity: 0.5 }} />}
          link={PRODUCT_LINK_4}
        />
        <FeatureCard
          title={PRODUCT_SUBTITLE_5}
          body={PRODUCT_BODY_5}
          icon={<CodeRounded sx={{ color: "#FFFFFF", opacity: 0.5 }} />}
          link={PRODUCT_LINK_5}
        />
        <FeatureCard
          title={PRODUCT_SUBTITLE_6}
          body={PRODUCT_BODY_6}
          icon={<ShowChartRounded sx={{ color: "#FFFFFF", opacity: 0.5 }} />}
          link={PRODUCT_LINK_6}
        />
      </>
    );
  };

  const MobileView = () => {
    return (
      <div
        style={{
          backgroundImage: "url(" + blackBackground + ")",
          backgroundSize: "200vw auto",
        }}
      >
        <Stack sx={{ alignItems: "center" }}>
          <Stack
            sx={{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "50px",
              paddingBottom: "50px",
              // height: "100vh",
              justifyContent: "center",
            }}
          >
            <Stack spacing={1} sx={{ marginBottom: "40px" }}>
              <Typography variant="h3" sx={{ color: "#FFFFFF", opacity: 0.5 }}>
                {PRODUCT_OVERLINE}
              </Typography>
              <Typography variant="h2" sx={{ color: "#FFFFFF" }}>
                {PRODUCT_TITLE}
              </Typography>
            </Stack>
            <Grid container spacing={2}>
              <NestedCards />
            </Grid>
          </Stack>
        </Stack>
      </div>
    );
  };
  const WebView = () => {
    return (
      <div
        style={{
          backgroundImage: "url(" + blackBackground + ")",
          backgroundSize: "101vw auto",
          minHeight: "101vh"
        }}
      >
        <Stack sx={{ alignItems: "center" }}>
          <Stack
            sx={{
              paddingLeft: "240px",
              paddingRight: "240px",
              paddingBottom: "20px",
              paddingTop: "20px",
              minHeight: "100vh",
              justifyContent: "center",
            }}
          >
            <Stack spacing={1} sx={{ marginBottom: "40px" }}>
              <Typography variant="h3" sx={{ color: "#FFFFFF", opacity: 0.5 }}>
                {PRODUCT_OVERLINE}
              </Typography>
              <Typography variant="h2" sx={{ color: "#FFFFFF" }}>
                {PRODUCT_TITLE}
              </Typography>
            </Stack>
            <Grid container spacing={6} sx={{ width: "1020px" }}>
              <NestedCards />
            </Grid>
          </Stack>
        </Stack>
      </div>
    );
  };

  return isMobile ? <MobileView /> : <WebView />;
}
