import { Card, Stack, Typography } from "@mui/material";
import { useMobileWindow } from "../util/responsive";

export default function Vault(props) {
  const isMobile = useMobileWindow();

  const WebView = () => {
    return (
      <Card
        variant="outlined"
        elevation={0}
        sx={{
          height: "400px",
          width: "300px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
        onClick={props.onClick}
        className={props.className}
      >
        <img src={props.image} style={{ height: "300px", width: "300px" }} />
        <Stack
          sx={{
            alignText: "right",
            alignItems: "flex-end",
            paddingTop: "20px",
            paddingRight: "20px !important",
          }}
        >
          <Typography variant="caption" sx={{ opacity: 0.5 }}>
            Current Buyout Price
          </Typography>
          <Typography variant="h6">{props.value} SOL</Typography>
        </Stack>
      </Card>
    );
  };

  const MobileView = () => {
    return (
      <Card
        variant="outlined"
        elevation={0}
        sx={{
          height: "300px",
          width: "225px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
        onClick={props.onClick}
        className={props.className}
      >
        <img src={props.image} style={{ height: "225px", width: "225px" }} />
        <Stack
          sx={{
            alignText: "right",
            alignItems: "flex-end",
            paddingTop: "20px",
            paddingRight: "20px !important",
          }}
        >
          <Typography variant="caption" sx={{ opacity: 0.5 }}>
            Current Buyout Price
          </Typography>
          <Typography variant="h6">4,356 SOL</Typography>
        </Stack>
      </Card>
    );
  };

  return isMobile ? <MobileView /> : <WebView />;
}
