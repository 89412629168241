import { Stack, Typography } from "@mui/material";
import {
  APP,
  APP_LINK,
  BLOG,
  BLOG_LINK,
  CAREERS,
  CAREERS_LINK,
  DISCORD,
  DISCORD_LINK,
  DOCS,
  DOCS_LINK,
  TWITTER,
  TWITTER_LINK,
} from "../util/labels";
import { useMobileWindow } from "../util/responsive";

export default function FooterLinks() {
  return (
    <Stack spacing={1} sx={{ color: "white", textAlign: "right" }}>
      <Typography>
        <a href={APP_LINK} target="_blank">
          {APP}
        </a>
      </Typography>
      <Typography>
        <a href={DOCS_LINK} target="_blank">
          {DOCS}
        </a>
      </Typography>
      <Typography>
        <a href={BLOG_LINK} target="_blank">
          {BLOG}
        </a>
      </Typography>
      <Typography>
        <a href={TWITTER_LINK} target="_blank">
          {TWITTER}
        </a>
      </Typography>
      <Typography>
        <a href={DISCORD_LINK} target="_blank">
          {DISCORD}
        </a>
      </Typography>
      <Typography>
        <a href={CAREERS_LINK} target="_blank">
          {CAREERS}
        </a>
      </Typography>
    </Stack>
  );
}
