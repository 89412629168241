import MuiAppBar from "@mui/material/AppBar";
import {
  Stack,
  Typography,
  Menu,
  MenuItem,
  Toolbar,
  Button,
} from "@mui/material";
import { logoBlack } from "../assets";
import { Menu as MenuIcon } from "@mui/icons-material";
import {
  DOCS_LINK,
  DISCORD_LINK,
  CAREERS_LINK,
  TWITTER_LINK,
  BLOG_LINK,
} from "../util/labels";
import { useMobileWindow } from "../util/responsive";
import { useState } from "react";

export function AppBar() {
  const isMobile = useMobileWindow();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Links = () => {
    return isMobile ? (
      <div>
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MenuIcon></MenuIcon>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          open={open}
          onClose={handleClose}
        >
          <a href={DOCS_LINK} rel="noreferrer" target="_blank">
            <MenuItem>
              <Typography color="secondary" variant="h6">
                Docs
              </Typography>
            </MenuItem>
          </a>

          <a href={TWITTER_LINK} rel="noreferrer" target="_blank">
            <MenuItem>
              <Typography color="secondary" variant="h6">
                Twitter
              </Typography>
            </MenuItem>
          </a>
          <a href={DISCORD_LINK} rel="noreferrer" target="_blank">
            <MenuItem>
              <Typography color="secondary" variant="h6">
                Discord
              </Typography>
            </MenuItem>
          </a>

          <a href={BLOG_LINK} rel="noreferrer" target="_blank">
            <MenuItem>
              <Typography color="secondary" variant="h6">
                Blog
              </Typography>
            </MenuItem>
          </a>

          {/* <a target="_blank">
            <MenuItem>
              <Typography color="secondary" variant="h6">
                For Developers
              </Typography>
            </MenuItem>
          </a> */}
          <a href={CAREERS_LINK} rel="noreferrer" target="_blank">
            <MenuItem>
              <Typography color="secondary" variant="h6">
                Careers
              </Typography>
            </MenuItem>
          </a>
        </Menu>
      </div>
    ) : (
      <Stack spacing={4} direction="row" sx={{}}>
        <a href={DOCS_LINK} rel="noreferrer" target="_blank">
          <Typography color="secondary" variant="h6">
            Docs
          </Typography>
        </a>

        <a href={TWITTER_LINK} rel="noreferrer" target="_blank">
          <Typography color="secondary" variant="h6">
            Twitter
          </Typography>
        </a>
        <a href={DISCORD_LINK} rel="noreferrer" target="_blank">
          <Typography color="secondary" variant="h6">
            Discord
          </Typography>
        </a>

        <a href={BLOG_LINK} rel="noreferrer" target="_blank">
          <Typography color="secondary" variant="h6">
            Blog
          </Typography>
        </a>

        {/* <a target="_blank">
          <Typography color="secondary" variant="h6">
            For Developers
          </Typography>
        </a> */}
        <a href={CAREERS_LINK} rel="noreferrer" target="_blank">
          <Typography color="secondary" variant="h6">
            Careers
          </Typography>
        </a>
      </Stack>
    );
  };

  const Logo = () => {
    return (
      <img
        style={{
          maxHeight: isMobile ? "30px" : "60px",
        }}
        alt="Bridgesplit Logo"
        src={logoBlack}
      />
    );
  };

  const DocsButton = () => {
    return (
      <a href={DOCS_LINK} rel="noreferrer" target="_blank">
      <Button
        variant="outlined"
        sx={{
          maxWidth: isMobile ? "100px" : "200px",
          height: isMobile ? "45px" : "auto",
        }}
        color="secondary"
        target="_blank"
      >
        {isMobile ? "Docs" : "Read docs"}
      </Button>
      </a>
    );
  };

  const MobileToolbar = () => {
    return (
      <Toolbar
        sx={{
          paddingLeft: "25px !important",
          paddingRight: "25px !important",
        }}
      >
        <Stack
          spacing={1}
          sx={{
            width: "100%",

            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack
            direction="row"
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Logo />
            <Stack direction="row">
              <Links />
              <DocsButton />
            </Stack>
          </Stack>
        </Stack>
      </Toolbar>
    );
  };

  const WebToolbar = () => {
    return (
      <Toolbar
        sx={{
          paddingLeft: "40px !important",
          paddingRight: "40px !important",
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
      >
        <Stack
          direction="row"
          sx={{
            width: "100%",

            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "rgba(0, 0, 0, 0)",
          }}
        >
          <Logo />
          <Links />
          <DocsButton />
        </Stack>
      </Toolbar>
    );
  };

  return (
    <MuiAppBar
      position={"static"}
      sx={{
        paddingTop: isMobile ? "40px" : "20px",
        backgroundColor: "white",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        height: "10vh",
      }}
      elevation={0}
    >
      {isMobile ? <MobileToolbar /> : <WebToolbar />}
    </MuiAppBar>
  );
}
