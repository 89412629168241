import { Stack, Typography } from "@mui/material";
import { OpenInNewRounded } from "@mui/icons-material";
import { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Vault } from "./";
import styles from "./../util/Vault.module.css";
import { vaultImage1, vaultImage2, vaultImage3 } from "../assets";
import { useMobileWindow } from "../util/responsive";
import { VAULT_CARD } from "../util/labels";

export default function VaultCarousel() {
    const isMobile = useMobileWindow();
    const [step, setStep] = useState(0);
    const [prevStep, setPrevStep] = useState(null);

    const nextStep = () => {
        setPrevStep(step);
        if (step === 2) setStep(0);
        else setStep(step + 1);
    };
    const previousStep = () => {
        setPrevStep(step);
        if (step === 0) setStep(2);
        else setStep(step - 1);
    };

    const handleOnClick = (id) => {
        if (id === VAULT_CARD.LEFT) {
            if (step === 0) nextStep();
            else if (step === 1) previousStep();
        } else if (id === VAULT_CARD.PRIMARY) {
            if (step === 1) nextStep();
            else if (step === 2) previousStep();
        } else if (id === VAULT_CARD.RIGHT) {
            if (step === 2) nextStep();
            else if (step === 0) previousStep();
        }
    };

    const getClassNames = (id) => {
        if (id === VAULT_CARD.LEFT) {
            return classNames(
                styles.left,
                !prevStep && styles.leftInitial,
                step === 1 && prevStep === 0 && styles.leftToRight,
                step === 0 && prevStep === 2 && styles.primaryToLeft,
                step === 2 && prevStep === 1 && styles.rightToPrimary,
                step === 2 && prevStep === 0 && styles.leftToPrimary,
                step === 1 && prevStep === 2 && styles.primaryToRight,
                step === 0 && prevStep === 1 && styles.rightToLeft
            );
        } else if (id === VAULT_CARD.PRIMARY) {
            return classNames(
                styles.primary,
                !prevStep && styles.primaryInitial,
                step === 1 && prevStep === 0 && styles.primaryToLeft,
                step === 0 && prevStep === 2 && styles.rightToPrimary,
                step === 2 && prevStep === 1 && styles.leftToRight,
                step === 2 && prevStep === 0 && styles.primaryToRight,
                step === 1 && prevStep === 2 && styles.rightToLeft,
                step === 0 && prevStep === 1 && styles.leftToPrimary
            );
        } else {
            if (id === VAULT_CARD.RIGHT) {
                return classNames(
                    styles.right,
                    !prevStep && styles.rightInitial,
                    step === 1 && prevStep === 0 && styles.rightToPrimary,
                    step === 0 && prevStep === 2 && styles.leftToRight,
                    step === 2 && prevStep === 1 && styles.primaryToLeft,
                    step === 2 && prevStep === 0 && styles.rightToLeft,
                    step === 1 && prevStep === 2 && styles.leftToPrimary,
                    step === 0 && prevStep === 1 && styles.primaryToRight
                );
            }
        }
    };

    return (
        <Stack sx={{ alignItems: "flex-end" }} spacing={1}>
            <Stack
                direction="row"
                spacing={isMobile ? -16 : -20}
                sx={{ marginRight: isMobile ? "-60px !important" : "0px" }}
            >
                <Vault
                    onClick={() => handleOnClick(VAULT_CARD.LEFT)}
                    image={vaultImage1}
                    value={4241.24}
                    className={getClassNames(VAULT_CARD.LEFT)}
                />
                <Vault
                    onClick={() => handleOnClick(VAULT_CARD.PRIMARY)}
                    image={vaultImage2}
                    value={31.6}
                    className={getClassNames(VAULT_CARD.PRIMARY)}
                />
                <Vault
                    onClick={() => handleOnClick(VAULT_CARD.RIGHT)}
                    image={vaultImage3}
                    value={413.30}
                    className={getClassNames(VAULT_CARD.RIGHT)}
                />
            </Stack>
            {!isMobile ? (
                <a
                    href="https://app.bridgesplit.com/vaults"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        textDecoration: "none"
                    }}
                >
                    <Typography
                        sx={{
                            color: "#C8c8c8",
                            alignSelf: "flex-end",
                            justifySelf: "flex-end",
                            cursor: "pointer"
                        }}
                        variant="h6"
                    >
                        Explore vaults <OpenInNewRounded fontSize="small" sx={{ marginBottom: "-6px" }} />
                    </Typography>
                </a>
            ) : null}
        </Stack>
    );
}
