export default function CardBanner() {
  return (
    <div
      style={{
        width: "500px",
        overflow: "hidden",
        width: "700px",
        zIndex: "-1",
      }}
    >
      <div class="sliding-background-reverse" />
    </div>
  );
}
