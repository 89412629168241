import React from "react";
import { AppBar } from "./components";
import { Partners, Vision, Product, Landing, Footer } from "./sections";

export default function HomePage() {
  return (
    <div>
      <AppBar />
      <Landing />
      <Partners />
      <Vision />
      <Product />
      <Footer />
    </div>
  );
}
