import { Button, Stack, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/system";

import {
  jump,
  coinbase,
  coinfund,
  solana,
  notboring,
  ruckerpark,
  partnerVectorLeft,
  partnerVectorRight,
} from "../assets/";
import { PARTNERS_CTA, PARTNERS_OVERLINE, SEED_RAISE_LINK } from "../util/labels";
import { useMobileWindow } from "../util/responsive";

export default function Partners() {
  const isMobileView = useMobileWindow();
  const theme = useTheme();
  const WebView = () => {
    return (
      <Stack
        direction="row"
        sx={{
          height: "65vh",
          backgroundColor: theme.palette.secondary.main,
          alignItems: "center",
          width: "100vw",
          justifyContent: "space-between",
        }}
      >
        <img
          src={partnerVectorLeft}
          alt="beautiful vector"
          style={{ height: "50vh", alignSelf: "flex-start" }}
        />
        <Stack
          spacing={5}
          sx={{
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Stack spacing={4}>
            <Typography
              variant="h3"
              sx={{
                color: `${theme.palette.secondary.contrastText}`,
                opacity: "0.5",
              }}
            >
              {PARTNERS_OVERLINE}
            </Typography>
            <div>
              <Grid container spacing={8} sx={{ width: "780px" }}>
                <Grid item xs={4}>
                  <img src={jump} alt="Jump Capital" style={{ width: "180px" }} />
                </Grid>
                <Grid item xs={4}>
                  <img src={coinbase} 
                      alt="Coinbase"
                      style={{ width: "180px" }} />
                </Grid>
                <Grid item xs={4}>
                  <img
                    src={coinfund}
                    alt="CoinFund"
                    style={{
                      width: "180px",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <img src={solana} 
                    alt="Solana Capital"
                    style={{ width: "180px" }} />
                </Grid>
                <Grid item xs={4}>
                  <img
                    src={notboring}
                    alt="Not Boring Capital"
                    style={{
                      width: "180px",
                    }}
                  />
                </Grid>
                <Grid item xs={4} style={{width: "180px"}}>
                  <img
                    src={ruckerpark}
                    alt="Rucker Park Capital"
                    style={{
                      width: "100px",
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Stack>
          <a href={SEED_RAISE_LINK} rel="noreferrer" target="_blank">
          <Button
            variant="outlined"
            sx={{
              color: `${theme.palette.secondary.contrastText}`,
              borderColor: theme.palette.secondary.contrastText,
              width: "350px",
            }}
          >
            {PARTNERS_CTA}
          </Button>
          </a>
        </Stack>
        <img
          src={partnerVectorRight}
          alt="beautiful vector"
          style={{ height: "50vh", alignSelf: "flex-end" }}
        />
      </Stack>
    );
  };

  const MobileView = () => {
    return (
      <Stack
        spacing={5}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          paddingBottom: "80px",
          paddingTop: "80px",
          backgroundColor: theme.palette.secondary.main,
          alignItems: "center",
        }}
      >
        <Stack spacing={4}>
          <Typography
            variant="h3"
            sx={{
              color: `${theme.palette.secondary.contrastText}`,
              opacity: "0.5",
            }}
          >
            {PARTNERS_OVERLINE}
          </Typography>
          <div>
            <Grid container spacing={4} sx={{ width: "280px" }}>
              <Grid item xs={6}>
                <img src={jump} alt="Jump Capital" style={{ width: "100px" }} />
              </Grid>
              <Grid item xs={6}>
                <img src={coinbase} alt="Coinbase" style={{ width: "100px" }} />
              </Grid>
              <Grid item xs={6}>
                <img
                  src={coinfund}
                  alt="CoinFund"
                  style={{
                    width: "100px",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <img src={solana} alt="Solana Capital" style={{ width: "100px" }} />
              </Grid>
            </Grid>
          </div>
        </Stack>
        <a href={SEED_RAISE_LINK} rel="noreferrer" target="_blank">
        <Button
          variant="outlined"
          sx={{
            color: `${theme.palette.secondary.contrastText}`,
            borderColor: theme.palette.secondary.contrastText,
            width: "300px",
          }}
        >
          {PARTNERS_CTA}
        </Button>
        </a>
      </Stack>
    );
  };

  return isMobileView ? <MobileView /> : <WebView />;
}
