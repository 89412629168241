export const DOCS_BUTTON = "Read Docs";
export const APP_BUTTON = "Go To App";
export const EMAIL_BUTTON = "Stay updated";
export const DOCS_LINK = "https://docs.bridgesplit.com";
export const CAREERS_LINK = "https://jobs.solana.com/companies/bridgesplit";
export const APP_LINK = "https://app.bridgesplit.com";
export const BLOG_LINK = "https://blog.bridgesplit.com";
export const TWITTER_LINK = "https://twitter.com/bridgesplit";
export const DISCORD_LINK = "https://discord.gg/bridgesplit";
export const SEED_RAISE_LINK = "https://medium.com/bridgesplit/bridgesplit-announces-4-25-million-seed-round-75d72bcf3772";
export const DISCORD = "Discord";
export const BLOG = "Blog";
export const APP = "App";
export const EMAIL_TITLE = "Alpha in the inbox...";
export const EMAIL_INPUT = "Email address";
export const EMAIL_CTA = "Stay updated";
export const TWITTER = "Twitter";
export const CAREERS = "Careers";
export const DOCS = "Docs";
export const LANDING_CTA = "Go to App";
export const LANDING_VAULT_CTA = "Explore trending vaults";
export const PARTNERS_OVERLINE = "Trusted by the best";
export const VISION_OVERLINE = "Infrastructure for Tomorrow";
export const VISION_TITLE = "Composability for unique assets";

export const COMING_SOON = "COMING SOON";
export const LEARN_MORE = "Learn More";

export const PARTNERS_CTA = "Read our seed raise announcement";

export const LANDING_TITLE = "NFTs, meet DeFi";
export const LANDING_SUBTITLE =
    "Earn yield and get liquidity for non-fungible tokens via lending, indexes, fractionalization, derivatives and more";
export const VISION_BODY_1 =
    "The DeFi and NFT ecosystems exist in silos, causing $20B+ of value to sit idle in wallets. Today, NFTs are the cornerstone of the digital art, gaming and entertainment markets, tomorrow, they represent all unique value, from vesting ownership in a protocol to an on-chain mortgage.";
export const VISION_BODY_2 =
    "Bridgesplit enables any unique asset to become liquid and yield generative via NFT-collateralized lending, indexes, and more. Applications from marketplaces to games benefit from the Bridgesplit protocol.";
export const PRODUCT_OVERLINE = "The future of DeFi";
export const PRODUCT_TITLE = "Unlocking liquidity and yield for unique assets";
export const PRODUCT_SUBTITLE_1 = "Automated Indexes";
export const PRODUCT_BODY_1 = "Trade permissionless indexes of assets to access instant liquidity";
export const PRODUCT_SUBTITLE_2 = "NFT-Collateralized Lending ";
export const PRODUCT_BODY_2 =
    "Instantly take out a USDC loan using an NFT as collateral to trade, farm, or spend capital sitting in NFTs";
export const PRODUCT_SUBTITLE_3 = "Fractionalization";
export const PRODUCT_BODY_3 =
    "Fractionalize a single NFT or an index to enable partial liquidity for owners and broader access to blue-chips";
export const PRODUCT_LINK_3 = "https://docs.bridgesplit.com/main/fractionalization";
export const PRODUCT_SUBTITLE_4 = "Asset leasing";
export const PRODUCT_BODY_4 = "Elevate an in-game experience or generate yield by  temporarily renting idle NFTs.";
export const PRODUCT_LINK_4 = "https://y1wlotbjq25.typeform.com/to/sM4Ph53N";
export const PRODUCT_SUBTITLE_5 = "Liquidity SDK";
export const PRODUCT_BODY_5 =
    "Embed NFT indexes into any gaming, lending or trading application for liquid and profitable NFT trading";
export const PRODUCT_LINK_5 = "https://y1wlotbjq25.typeform.com/to/sM4Ph53N";

export const PRODUCT_SUBTITLE_6 = "Yield";
export const PRODUCT_BODY_6 =
    "Earn passive income while holding NFTs or NFT backed assets by providing liquidity to NFT indexes.";
export const PRODUCT_LINK_6 = "https://app.bridgesplit.com/yield";

export const VAULT_CARD = {
    LEFT: "LEFT",
    PRIMARY: "PRIMARY",
    RIGHT: "RIGHT"
};
