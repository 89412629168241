import { Button, Stack, Typography } from "@mui/material";

import { ButtonBanner, CardBanner } from "../components";
import { mock } from "../assets";

import {
  VISION_BODY_1,
  VISION_BODY_2,
  APP_BUTTON,
  VISION_OVERLINE,
  VISION_TITLE,
} from "../util/labels";
import { useMobileWindow } from "../util/responsive";

export default function Vision() {
  const isMobileView = useMobileWindow();
  const MobileView = () => {
    return (
      <Stack
        spacing={2}
        sx={{
          paddingTop: "50px",
          justifyContent: "center",
          paddingRight: "20px",
          paddingLeft: "20px",
          paddingBottom: "50px",
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h3" color="primary" sx={{ opacity: 0.5 }}>
            {VISION_OVERLINE}
          </Typography>
          <Typography variant="h2" color="secondary">
            {VISION_TITLE}
          </Typography>
        </Stack>
        <Stack spacing={4}>
          <Typography variant="body1" color="secondary">
            {VISION_BODY_1}
          </Typography>
          <Stack
            spacing={1}
            sx={{
              maxWidth: "100vw",
              marginLeft: "-20px !important",
            }}
          >
            <ButtonBanner />
            <CardBanner />
          </Stack>
          <Typography
            variant="body1"
            color="secondary"
            sx={{ paddingRight: "20px" }}
          >
            {VISION_BODY_2}
          </Typography>

          <a href="https://app.bridgesplit.com" target="_blank" rel="noreferrer">
          <Button variant="outlined" color="primary" sx={{ width: "150px" }}>
            {APP_BUTTON}
          </Button>
          </a>
        </Stack>
      </Stack>
    );
  };
  const WebView = () => {
    return (
      <Stack
        spacing={2}
        sx={{ height: "100vh", paddingLeft: "240px", justifyContent: "center" }}
      >
        <Stack spacing={1}>
          <Typography variant="h3" color="primary" sx={{ opacity: 0.5 }}>
            {VISION_OVERLINE}
          </Typography>
          <Typography variant="h2" color="secondary">
            {VISION_TITLE}
          </Typography>
        </Stack>
        <Stack direction="row">
          <Stack spacing={4}>
            <Typography
              variant="body1"
              color="secondary"
              sx={{ width: "500px" }}
            >
              {VISION_BODY_1}
            </Typography>
            <Stack spacing={1}>
              <ButtonBanner />
              <CardBanner />
            </Stack>
            <Typography
              variant="body1"
              color="secondary"
              sx={{ width: "500px" }}
            >
              {VISION_BODY_2}
            </Typography>

            <a href="https://app.bridgesplit.com" target="_blank" rel="noreferrer">
            <Button variant="outlined" color="primary" sx={{ width: "150px" }}>
              {APP_BUTTON}
            </Button>
            </a>
          </Stack>
          <img
            src={mock}
            alt="bridgesplit mockup"
            style={{
              height: "500px",
              zIndex: 10,
              marginLeft: "-100px",
              alignSelf: "flex-end",
              borderRadius: "5px",
            }}
          />
        </Stack>
      </Stack>
    );
  };
  return isMobileView ? <MobileView /> : <WebView />;
}
