import { Stack } from "@mui/material";
import { logoWhite } from "../assets";
import { useMobileWindow } from "../util/responsive";
import { FooterLinks, EmailForm } from "../components";

export default function Footer() {
  const isMobile = useMobileWindow();
  return (
    <div
      style={{
        width: "100%",
        height: isMobile ? "auto" : "300px",
        backgroundColor: "#282937",
        paddingTop: "10px",
        alignItems: "center",
        display: "flex",
        alignContent: "center",
      }}
    >
      <Stack
        direction="row"
        sx={{
          paddingTop: isMobile ? "20px" : "",
          paddingBottom: isMobile ? "20px" : "",
          paddingLeft: isMobile ? "20px" : "100px",
          paddingRight: isMobile ? "20px" : "100px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Stack spacing={2}>
          <img src={logoWhite} style={{ width: "200px" }} />
          <EmailForm />
        </Stack>
        <FooterLinks />
      </Stack>
    </div>
  );
}
