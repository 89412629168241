import { Typography, Stack, InputBase, Button } from "@mui/material";
import { EMAIL_CTA, EMAIL_INPUT, EMAIL_TITLE } from "../util/labels";
import { useMobileWindow } from "../util/responsive";
export default function EmailForm() {
  const isMobile = useMobileWindow();
  return (
    <div id="revue-embed">
      <form
        action="https://www.getrevue.co/profile/bridgesplit/add_subscriber"
        method="post"
        id="revue-form"
        name="revue-form"
        target="_blank"
      >
        <Stack direction={isMobile ? "column" : "row"} spacing={1}>
          <InputBase
            class="revue-form-field"
            placeholder={EMAIL_INPUT}
            type="email"
            name="member[email]"
            id="member_email"
            sx={{
              // backgroundColor: "#FFFFFF  !important",
              borderRadius: "5px",
              maxHeight: "50px !important",
              width: isMobile ? "100%" : "300px",
              maxWidth: "300px",
              height: "50px  !important",
              border: "1px solid #FFFFFF",
              paddingLeft: "10px",
              color: "#FFFFFF",
            }}
          />
          <Button
            sx={{
              backgroundColor: "#FFFFFF",
              color: "#121219 !important",
              width: isMobile ? "80%" : "200px",
              maxWidth: "300px",
            }}
            type="submit"
            value="Subscribe"
            name="member[subscribe]"
            id="member_submit"
            disableElevation
          >
            {EMAIL_CTA}
          </Button>
        </Stack>
      </form>
    </div>
  );
}
